/**
 * Created by Giovanni E Valencia on 11/02/2017.
 */
window.onload = function() {
    var slider = $("#Slider-Home");
    slider.find("button.slick-prev img").attr('src', getImg('prev'));
    slider.find("button.slick-next img").attr('src', getImg('next'));
    var btn =  null;
    slider.find("button.slick-arrow").mouseenter(function(){
        btn = $(this);
        btn.find('img').addClass('expand');
    }).mouseleave(function(){
        btn = $(this);
        btn.find('img').removeClass('expand');
    });
    slider.on('afterChange', function(){
        slider.find("button.slick-prev img").attr('src', getImg('prev'));
        slider.find("button.slick-next img").attr('src', getImg('next'));
    });
}
function getImg(dir) {
    var slider = $("#Slider-Home");
    var cSlider = slider.find(".slick-current.slick-active");
    if(dir == 'prev') {
        var prev = cSlider.prev();
        if(prev.length == 0)
            prev = slider.find('.slick-slide:last-child');
        return prev.find('img').attr('src');
    }
    else if(dir == 'next') {
        var next = cSlider.next();
        if(next.length == 0)
            next = slider.find('.slick-slide:first-child');
        return next.find('img').attr('src');
    }
}